import React, { useState } from 'react';
import axios from 'axios';

export default function Signup() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        privacy: true
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const validateForm = () => {
        const newErrors = {};

        if (!formData.name) newErrors.name = "Name is required";
        if (!formData.email) newErrors.email = "Email is required";
        else if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = "Email is invalid";
        if (!formData.phone) newErrors.phone = "Phone number is required";

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                const response = await axios.post('https://watchlist.capital/api/signup.php', formData);
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    privacy: true
                })
                alert("Your Singup is successfully done")
                console.log('Form submitted successfully:', response.data);
            } catch (error) {
                console.error('Error submitting form:', error);
            }
        }
    };

    return (
        <div>
            <form onSubmit={handleSubmit} className="form form--modal">
                <input
                    type="text"
                    name="name"
                    className="form__input"
                    placeholder="Name"
                    value={formData.name}
                    onChange={handleChange}
                />
                {errors.name && <div className="form__error" style={{position:'relative',bottom:'20px', color:'red', left:'8px'}} >{errors.name}</div>}
                
                <input
                    type="text"
                    name="email"
                    className="form__input"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                />
                {errors.email && <div className="form__error" style={{position:'relative',bottom:'20px', color:'red', left:'8px'}} >{errors.email}</div>}
                
                <input
                    type="number"
                    name="phone"
                    className="form__input"
                    placeholder="Phone No."
                    value={formData.phone}
                    onChange={handleChange}
                />
                {errors.phone && <div className="form__error" style={{position:'relative',bottom:'20px', color:'red', left:'8px'}} >{errors.phone}</div>}
                
                <div className="form__checkbox">
                    <input
                        id="privacy1"
                        name="privacy"
                        type="checkbox"
                        checked={formData.privacy}
                        onChange={handleChange}
                    />
                    <label htmlFor="privacy1">
                        I agree to the{" "}
                        <a href="#privacy" className="modal-article">
                            Privacy Policy
                        </a>
                    </label>
                </div>
                
                <button className="form__btn" type="submit">
                    <span>Submit</span>
                </button>
            </form>
        </div>
    );
}
