import Home from "./Home";
import { HashRouter, Routes, Route } from "react-router-dom";


function App() {
  return (
    <>
    <HashRouter>
            <Routes>
              <Route >
                <Route index element={<Home />} />
              </Route>
            </Routes>
          </HashRouter>
    </>
  );
}

export default App;
