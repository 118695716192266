import React, { useState } from 'react';
import axios from 'axios';

export default function ContactForm() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const validateForm = () => {
        const newErrors = {};

        if (!formData.name) newErrors.name = "Name is required";
        if (!formData.email) newErrors.email = "Email is required";
        else if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = "Email is invalid";
        if (!formData.message) newErrors.message = "Message is required";

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
            e.preventDefault();
            if (validateForm()) {
                try {
                    const response = await axios.post('https://watchlist.capital/api/contact.php', formData);
                    setFormData({
                        name: '',
                        email: '',
                        message: '',
                    })
                    alert("Thanks for contacting us")           
                } catch (error) {
                    console.error('Error submitting form:', error);
                }
            }
        };
    
        return (
            <div>
                <form onSubmit={handleSubmit} className="form form--contacts">
                    <input
                        type="text"
                        name="name"
                        className="form__input"
                        placeholder="Name"
                        value={formData.name}
                        onChange={handleChange}
                    />
                    {errors.name && <div className="form__error" style={{position:'relative',bottom:'20px', color:'red', left:'8px'}}>{errors.name}</div>}
                    
                    <input
                        type="text"
                        name="email"
                        className="form__input"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                    />
                    {errors.email && <div className="form__error" style={{position:'relative',bottom:'20px', color:'red', left:'8px'}}>{errors.email}</div>}
                    
                    <textarea
                        name="message"
                        className="form__textarea"
                        placeholder="Message"
                        value={formData.message}
                        onChange={handleChange}
                    />
                    {errors.message && <div className="form__error" style={{position:'relative',bottom:'20px', color:'red', left:'8px'}}>{errors.message}</div>}
                    
                    <button className="form__btn" type="submit">
                        <span>Send</span>
                    </button>
                </form>
            </div>
        );
    }
    