import React from 'react'
import Signup from './Signup';
import ContactForm from './Contact';
import { Link } from 'react-router-dom';

export default function Home() {
  return (
    <>
      <header className="header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="header__content">
                <button className="header__btn" type="button">
                  <span />
                  <span />
                  <span />
                </button>
                <Link data-scroll="" to="#home" className="header__logo">
                  <img
                    className="header__logo-white"
                    src="img/1.png"
                    alt="BuyCoin logo"
                  />
                  <img
                    className="header__logo-dark"
                    src="img/2.png"
                    alt="BuyCoin logo"
                  />
                </Link>
                {/* end logo */}
                {/* tagline */}
                <span className="header__tagline" />
                {/* end tagline */}
                {/* navigation */}
                {/* <ul class="header__nav">
							
							<li>
								<Link data-scroll to="#features">Home</Link>
							</li>
							<li>
								<Link data-scroll to="#safety">About</Link>
							</li>
							<li>
								<Link data-scroll to="#blog">Support</Link>
							</li>
							<li>
								<Link data-scroll to="#features">Pricing</Link>
							</li>
							
						</ul> */}
                {/* end navigation */}
                {/* sign in */}
                {/* <Link to="#signup" class="header__signin modal-btn"><span>Register</span></Link> */}
                {/* end sign in */}
              </div>
            </div>
          </div>
        </div>
      </header>

      <section id="home" className="home section--bg" data-bg="/img/home/slide.jpg">
        <div className="container">
          <div className="row">
            <div className="col-12 col-xl-7">
              <div className="home__content home__content--left">
                <h1 className="home__title">Watchlist Capital</h1>
                <p className="home__text">
                  Capitalize on Insights, Watchlist Your Way to Wealth.
                </p>
                <div className="home__btns">
                  <Link
                    data-scroll=""
                    to="#getstarted"
                    className="home__btn home__btn--white"
                  >
                    <span>WHAT WE DO</span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-5 offset-xl-0">
              <div className="home__content home__content--right">
                <div className="home__sales mt-4">
                  <h6 className="modal__title">Beta Sign Up</h6>
                  <Signup></Signup>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="about" className="about about--wave section--gradient">
        <div className="container">
          <div className="row">
            {/* section title */}
            <div className="col-12">
              <h2 className="section__title section__title--white section__title--margin">
                About Watchlist
              </h2>
            </div>
            {/* end section title */}
            <div className="col-12">
              {/* about text */}
              <div className="about__text">
                <p>
                  Welcome to Watchlist Capital, where your investment journey meets
                  advanced technology, precise analytics, and personalized insights.
                  Whether you're a seasoned trader or just starting, our platform is
                  designed to help you navigate the complexities of the stock market
                  with confidence.
                </p>
              </div>
              {/* end about text */}
            </div>
          </div>
        </div>
      </section>

      <section id="getstarted" className="section section--pt0">
        <div className="container">
          <div className="row">
            {/* section title */}
            <div className="col-12">
              <h2 className="section__title">WHAT WE DO</h2>
              <span className="section__tagline">
                Streamline your stock research with customizable dashboards &amp;
                watchlists, providing all relevant information in realtime from
                multiple sources in one convenient hub
              </span>
            </div>
            {/* end section title */}
            <div className="col-12 col-md-4">
              {/* box (style 4) */}
              <div className="box4">
                <span className="box4__number">01</span>
                <h3 className="box4__title">Customizable Watchlist</h3>
                <p className="box4__text">
                  The Watchlist' is at the heart of our platform, allowing users to
                  personalize their market tracking experience. Tailor it to monitor
                  the stocks you care about the most, ensuring you never miss critical
                  market movements. Whether it's small-cap wonders or blue-chip
                  stalwarts, 'The Watchlist' keeps them at your fingertips
                </p>
              </div>
              {/* end box (style 4) */}
            </div>
            <div className="col-12 col-md-4">
              {/* box (style 4) */}
              <div className="box4">
                <span className="box4__number">02</span>
                <h3 className="box4__title">Real-Time Alerts and Analytics</h3>
                <p className="box4__text">
                  With the 'Watchlist', stay ahead of the market with real-time alerts
                  and detailed analytics for each stock you follow. Our platform not
                  only tracks price movements but also integrates news, social
                  sentiment, and market trends specifically related to your chosen
                  stocks, offering a comprehensive view of each investment's
                  performance.
                </p>
              </div>
              {/* end box (style 4) */}
            </div>
            <div className="col-12 col-md-4">
              {/* box (style 4) */}
              <div className="box4">
                <span className="box4__number">03</span>
                <h3 className="box4__title">Upgrade to our premium plans</h3>
                <p className="box4__text">
                  Upgrade to our premium plans to unlock even greater control and
                  insights within the 'Watchlist' feature. Gain access to exclusive
                  real-time alerts, advanced charting tools, and personalized
                  recommendations tailored to your investment goals. With priority
                  customer support and regular updates.
                </p>
              </div>
              {/* end box (style 4) */}
            </div>
          </div>
        </div>
      </section>

      <section
        className="section section--bg video"
        data-bg="img/section-bg/section-bg2.jpg"
      >
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-10 offset-sm-1 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
              <Link to="" className="video__btn">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M18.54,9,8.88,3.46a3.42,3.42,0,0,0-5.13,3V17.58A3.42,3.42,0,0,0,7.17,21a3.43,3.43,0,0,0,1.71-.46L18.54,15a3.42,3.42,0,0,0,0-5.92Zm-1,4.19L7.88,18.81a1.44,1.44,0,0,1-1.42,0,1.42,1.42,0,0,1-.71-1.23V6.42a1.42,1.42,0,0,1,.71-1.23A1.51,1.51,0,0,1,7.17,5a1.54,1.54,0,0,1,.71.19l9.66,5.58a1.42,1.42,0,0,1,0,2.46Z" />
                </svg>
              </Link>
              <h4 className="video__title">How it Works?</h4>
              <p className="video__text">
                The Watchlist' is at the heart of our platform, allowing users to
                personalize their market tracking experience.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="section section--grey">
        <div className="container">
          <div className="row">
            {/* section title */}
            <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
              <h2 className="section__title">FAQ</h2>
              <p className="section__text">Got Questions? We've Got Answers!</p>
            </div>
            {/* end section title */}
          </div>
          <div className="accordion" id="accordion">
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="faq">
                  <div className="faq__btn" id="heading1">
                    <button
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapse1"
                      aria-expanded="false"
                      aria-controls="collapse1"
                    >
                      <span>How does the customizable watchlist feature work?</span>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z" />
                      </svg>
                    </button>
                  </div>
                  <div
                    id="collapse1"
                    className="collapse"
                    aria-labelledby="heading1"
                    data-parent="#accordion"
                  >
                    <p className="faq__text">
                      Our customizable watchlist feature allows you to curate a
                      personalized list of stocks that are of interest to you.
                      <br />
                      Don't have any yet? Don’t worry, we got your back with our top
                      picks!
                      <br />
                      Simply search for the stocks you want to track and add them to
                      your watchlist. You can easily manage and prioritize your
                      watchlist, ensuring you stay updated on the most important
                      market moves for your investments.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="faq">
                  <div className="faq__btn" id="heading2">
                    <button
                      className="collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapse2"
                      aria-expanded="false"
                      aria-controls="collapse2"
                    >
                      <span>What markets does your platform cover?</span>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z" />
                      </svg>
                    </button>
                  </div>
                  <div
                    id="collapse2"
                    className="collapse"
                    aria-labelledby="heading2"
                    data-parent="#accordion"
                  >
                    <p className="faq__text">
                      Our customizable watchlist feature allows you to curate a
                      personalized list of stocks that are of interest to you.
                      <br />
                      Don't have any yet? Don’t worry, we got your back with our top
                      picks!
                      <br />
                      Simply search for the stocks you want to track and add them to
                      your watchlist. You can easily manage and prioritize your
                      watchlist, ensuring you stay updated on the most important
                      market moves for your investments.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="faq">
                  <div className="faq__btn" id="heading3">
                    <button
                      className="collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapse3"
                      aria-expanded="false"
                      aria-controls="collapse3"
                    >
                      <span>Can I access the platform on mobile devices?</span>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z" />
                      </svg>
                    </button>
                  </div>
                  <div
                    id="collapse3"
                    className="collapse"
                    aria-labelledby="heading3"
                    data-parent="#accordion"
                  >
                    <p className="faq__text">
                      Our customizable watchlist feature allows you to curate a
                      personalized list of stocks that are of interest to you.
                      <br />
                      Don't have any yet? Don’t worry, we got your back with our top
                      picks!
                      <br />
                      Simply search for the stocks you want to track and add them to
                      your watchlist. You can easily manage and prioritize your
                      watchlist, ensuring you stay updated on the most important
                      market moves for your investments.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="faq">
                  <div className="faq__btn" id="heading4">
                    <button
                      className="collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapse4"
                      aria-expanded="false"
                      aria-controls="collapse4"
                    >
                      <span>
                        What additional features are included in the premium plans?
                      </span>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z" />
                      </svg>
                    </button>
                  </div>
                  <div
                    id="collapse4"
                    className="collapse"
                    aria-labelledby="heading4"
                    data-parent="#accordion"
                  >
                    <p className="faq__text">
                      Our customizable watchlist feature allows you to curate a
                      personalized list of stocks that are of interest to you.
                      <br />
                      Don't have any yet? Don’t worry, we got your back with our top
                      picks!
                      <br />
                      Simply search for the stocks you want to track and add them to
                      your watchlist. You can easily manage and prioritize your
                      watchlist, ensuring you stay updated on the most important
                      market moves for your investments.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="faq">
                  <div className="faq__btn" id="heading5">
                    <button
                      className="collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapse5"
                      aria-expanded="false"
                      aria-controls="collapse5"
                    >
                      <span>How does the customizable watchlist feature work?</span>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z" />
                      </svg>
                    </button>
                  </div>
                  <div
                    id="collapse5"
                    className="collapse"
                    aria-labelledby="heading5"
                    data-parent="#accordion"
                  >
                    <p className="faq__text">
                      Our customizable watchlist feature allows you to curate a
                      personalized list of stocks that are of interest to you.
                      <br />
                      Don't have any yet? Don’t worry, we got your back with our top
                      picks!
                      <br />
                      Simply search for the stocks you want to track and add them to
                      your watchlist. You can easily manage and prioritize your
                      watchlist, ensuring you stay updated on the most important
                      market moves for your investments.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="faq">
                  <div className="faq__btn" id="heading6">
                    <button
                      className="collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapse6"
                      aria-expanded="false"
                      aria-controls="collapse6"
                    >
                      <span>What payment methods do you accept?</span>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z" />
                      </svg>
                    </button>
                  </div>
                  <div
                    id="collapse6"
                    className="collapse"
                    aria-labelledby="heading6"
                    data-parent="#accordion"
                  >
                    <p className="faq__text">
                      Our customizable watchlist feature allows you to curate a
                      personalized list of stocks that are of interest to you.
                      <br />
                      Don't have any yet? Don’t worry, we got your back with our top
                      picks!
                      <br />
                      Simply search for the stocks you want to track and add them to
                      your watchlist. You can easily manage and prioritize your
                      watchlist, ensuring you stay updated on the most important
                      market moves for your investments.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section id="contacts" className="section">
        <div className="container">
          <div className="row">
            {/* section title */}
            <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
              <h2 className="section__title">Get in Touch</h2>
              <p className="section__text">
                We are always open and we welcome and questions you have for our team.
                If you wish to get in touch, please fill out the form below.
              </p>
            </div>
            {/* end section title */}
            <div className="col-12 col-md-6">
              {/* contacts */}
              <div className="contacts">
                <ul className="contacts__list">
                  <li>
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M18,4.48a8.45,8.45,0,0,0-12,12l5.27,5.28a1,1,0,0,0,1.42,0L18,16.43A8.45,8.45,0,0,0,18,4.48ZM16.57,15,12,19.59,7.43,15a6.46,6.46,0,1,1,9.14,0ZM9,7.41a4.32,4.32,0,0,0,0,6.1,4.31,4.31,0,0,0,7.36-3,4.24,4.24,0,0,0-1.26-3.05A4.3,4.3,0,0,0,9,7.41Zm4.69,4.68a2.33,2.33,0,1,1,.67-1.63A2.33,2.33,0,0,1,13.64,12.09Z" />
                      </svg>
                    </span>
                    <p>
                      ByteCon Software Solutions
Gladbacher Str. 10
51429 Bergisch Gladbach
                    </p>
                  </li>
                  <li>
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M12,2A10,10,0,1,0,22,12,10.01114,10.01114,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.00917,8.00917,0,0,1,12,20ZM14.09814,9.63379,13,10.26807V7a1,1,0,0,0-2,0v5a1.00025,1.00025,0,0,0,1.5.86621l2.59814-1.5a1.00016,1.00016,0,1,0-1-1.73242Z" />
                      </svg>
                    </span>
                    <p>
                      Mon - Fri: <br />
                      08:00 - 19:00
                    </p>
                  </li>
                  <li>
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M19,4H5A3,3,0,0,0,2,7V17a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V7A3,3,0,0,0,19,4Zm-.41,2-5.88,5.88a1,1,0,0,1-1.42,0L5.41,6ZM20,17a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V7.41l5.88,5.88a3,3,0,0,0,4.24,0L20,7.41Z" />
                      </svg>
                    </span>
                    <Link to="mailto:support@buycoin.template">
                      support@Watchlist.capital
                    </Link>
                  </li>
                  <li>
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M12.71,16.29l-.15-.12a.76.76,0,0,0-.18-.09L12.2,16a1,1,0,0,0-.91.27,1.15,1.15,0,0,0-.21.33,1,1,0,0,0,1.3,1.31,1.46,1.46,0,0,0,.33-.22,1,1,0,0,0,.21-1.09A1,1,0,0,0,12.71,16.29ZM16,2H8A3,3,0,0,0,5,5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V5A3,3,0,0,0,16,2Zm1,17a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V5A1,1,0,0,1,8,4h8a1,1,0,0,1,1,1Z" />
                      </svg>
                    </span>
                    <Link to="tel:88002345678">8976212537</Link>
                  </li>
                </ul>
              </div>
              {/* end contacts */}
            </div>
            <div className="col-12 col-md-6">
              {/* form */}
              <ContactForm></ContactForm>
              {/* end form */}
            </div>
          </div>
        </div>
      </section>

      <footer className="footer">
        <div className="container">
          <div className="row">
            {/* section title */}
            <div className="col-12">
              <h2 className="section__title section__title--footer section__title--white">
                Subscribe for Our Newsletter
              </h2>
            </div>
            {/* end section title */}
            <div className="col-12">
              <form action="#" className="subscribe">
                <input
                  type="text"
                  className="subscribe__input"
                  placeholder="Enter your e-mail address"
                />
                <button type="button" className="subscribe__btn">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M20.34,9.32l-14-7a3,3,0,0,0-4.08,3.9l2.4,5.37h0a1.06,1.06,0,0,1,0,.82l-2.4,5.37A3,3,0,0,0,5,22a3.14,3.14,0,0,0,1.35-.32l14-7a3,3,0,0,0,0-5.36Zm-.89,3.57-14,7a1,1,0,0,1-1.35-1.3l2.39-5.37A2,2,0,0,0,6.57,13h6.89a1,1,0,0,0,0-2H6.57a2,2,0,0,0-.08-.22L4.1,5.41a1,1,0,0,1,1.35-1.3l14,7a1,1,0,0,1,0,1.78Z" />
                  </svg>
                </button>
              </form>
            </div>
            <div className="col-12">
              {/* social list */}
              <ul className="footer__social">
                <li>
                  <Link to="#" className="fb">
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 512 512"
                      style={{ enableBackground: "new 0 0 512 512" }}
                      xmlSpace="preserve"
                    >
                      <path
                        style={{ fillRule: "evenodd", clipRule: "evenodd" }}
                        d="M480,257.35c0-123.7-100.3-224-224-224s-224,100.3-224,224 c0,111.8,81.9,204.47,189,221.29V322.12h-56.89v-64.77H221v-49.36c0-56.13,33.45-87.16,84.61-87.16c24.51,0,50.15,4.38,50.15,4.38 v55.13h-28.26c-27.81,0-36.51,17.26-36.51,35v42.02h62.12l-9.92,64.77h-52.2v156.53C398.1,461.85,480,369.18,480,257.35L480,257.35z "
                      />
                    </svg>
                  </Link>
                </li>
                <li>
                  <Link to="#" className="inst">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={512}
                      height={512}
                      viewBox="0 0 512 512"
                    >
                      <path d="M349.33,69.33a93.62,93.62,0,0,1,93.34,93.34V349.33a93.62,93.62,0,0,1-93.34,93.34H162.67a93.62,93.62,0,0,1-93.34-93.34V162.67a93.62,93.62,0,0,1,93.34-93.34H349.33m0-37.33H162.67C90.8,32,32,90.8,32,162.67V349.33C32,421.2,90.8,480,162.67,480H349.33C421.2,480,480,421.2,480,349.33V162.67C480,90.8,421.2,32,349.33,32Z" />
                      <path d="M377.33,162.67a28,28,0,1,1,28-28A27.94,27.94,0,0,1,377.33,162.67Z" />
                      <path d="M256,181.33A74.67,74.67,0,1,1,181.33,256,74.75,74.75,0,0,1,256,181.33M256,144A112,112,0,1,0,368,256,112,112,0,0,0,256,144Z" />
                    </svg>
                  </Link>
                </li>
                <li>
                  <Link to="#" className="tw">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={512}
                      height={512}
                      viewBox="0 0 512 512"
                    >
                      <path d="M496,109.5a201.8,201.8,0,0,1-56.55,15.3,97.51,97.51,0,0,0,43.33-53.6,197.74,197.74,0,0,1-62.56,23.5A99.14,99.14,0,0,0,348.31,64c-54.42,0-98.46,43.4-98.46,96.9a93.21,93.21,0,0,0,2.54,22.1,280.7,280.7,0,0,1-203-101.3A95.69,95.69,0,0,0,36,130.4C36,164,53.53,193.7,80,211.1A97.5,97.5,0,0,1,35.22,199v1.2c0,47,34,86.1,79,95a100.76,100.76,0,0,1-25.94,3.4,94.38,94.38,0,0,1-18.51-1.8c12.51,38.5,48.92,66.5,92.05,67.3A199.59,199.59,0,0,1,39.5,405.6,203,203,0,0,1,16,404.2,278.68,278.68,0,0,0,166.74,448c181.36,0,280.44-147.7,280.44-275.8,0-4.2-.11-8.4-.31-12.5A198.48,198.48,0,0,0,496,109.5Z" />
                    </svg>
                  </Link>
                </li>
              </ul>
              {/* end social list */}
            </div>
            <div className="col-12">
              {/* copyright */}
              <small className="footer__copyright">© Watchlist Capital, 2024. </small>
              {/* end copyright */}
            </div>
          </div>
        </div>
      </footer>



    </>
  )
}
